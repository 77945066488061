<template>
	<div>
	    <b-table small :fields="fields" :items="items" responsive="lg" tbody-tr-class="size-14 table-row" @row-clicked="detail" id="overview-table" class="mb-5 cursor-pointer" show-empty>

	    	<template #table-caption>Study Visit Listing</template>	 
	    	<template #cell(name)="data">
				<span>{{ data.item.name }}</span>
			</template>			
			<template #cell()="data">
				<span class="d-block text-left" style="padding-left: 2.5rem !important">{{ data.value }}</span>
			</template>
			<template #empty="scope">
				<template v-if="search">
					<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data found</h4>
				</template>
				<template v-else>
					<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
				</template>
		    </template>
			    
		</b-table>
		<div class="paging-cus relative">
			<b-pagination
		      	v-model="currentPage"
		      	:total-rows="total"
		      	:per-page="perPage"
		      	aria-controls="overview-table"
		      	first-number
	        	last-number
	        	class="just-center"
		    ></b-pagination>
		</div>
	</div>

</template>

<script>

	export default {
	  	components: {},
	  	props: {
            search: {
                type: String,
                default: null
            }
        },
	  	data() {
		    return {
                fields: [
			        { key: 'name', label: 'Country'},
			        { key: 'total_terminated', label: 'Terminated',},
			    ],
		    	items: [],
			    sort: {
			    	field: 'country',
			    	type: 'asc'
			    },
			    perPage: 10,
        		currentPage: 1, 
        		total: 0,
            }
		},
		watch: {
			currentPage(val) {
				this.fetchItem(this.search)
			}
		},
		created(){
			this.fetchItem();			
		},
		methods: {
		  	fetchItem(search){
				this.$store.dispatch('project/fetchStudyVisit', {
					limit: this.perPage,
					page: this.currentPage,
					search: search,
					filter: 2,
					sort: {"field": "country_name","type": "asc"}
				})
				.then(response => {
					this.items = response.data.data.data
					this.total = response.data.data.total
				})
				.catch((error) => {
					console.log(error)
				})
			},
			detail(item){
				this.$router.push(`/admin/our-portfolio/study-visit/${item.id}`)
			}
		},
	};	

</script>
<style lang="scss">
	.study-remain-tab thead tr th:first-child{
		width: 305px
	}
</style>