<template>
	<section id="project" class="porfolio-breadcrumb">
		<b-row style="justify-content: space-between; align-items: center" class="mb-2">
		    <b-col lg="8">
				<Breadcrumb :labels="labels" />
		    </b-col>
		    <b-col lg="4">
		    	<div class="icon-search-wrapper ml-auto" style="max-width: 346px; width: 100%;">
			      	<b-form-group class="mb-0">
				        <b-input-group class="input-group-merge">
					        <b-input-group-prepend is-text>
					            <feather-icon icon="SearchIcon" size="17" />
					        </b-input-group-prepend>
					        <b-form-input
					            placeholder="Search"
					            @input="searchValue"
					        />
				        </b-input-group>
			      	</b-form-group>
			    </div>
		    </b-col>
		</b-row>
		<b-row>
		    <b-col md="12">  
		    	<div class="btn-action text-right mb-1">

		            <b-button type="button" variant="primary" class="px-1 mr-10 py-1  size-18 btn-df" @click="exportData(export_num)">Export</b-button>

		            <template v-if="(userData && permission.create)">

				    	<b-button type="button" variant="primary" class="btn-df py-1 size-18" @click="addItem">Add Visit</b-button>

				    </template>
				    <template v-else>
				    	<b-button type="button" variant="primary" class="btn-df py-1 size-18 btn-disabled-gray" disabled>Add Visit</b-button>
				    </template>

		        </div>
		    	<b-tabs card class="caption-add" v-model="tabIndex">                       
		            <b-tab title="Overview" class="overview-tab table-custom ">
		                <div class="content-tab info-content">
		                    <Overview ref="tab_0" :search="search" />
		                </div>
		            </b-tab>
		            <b-tab title="Paid" class="study-remain-tab table-custom">
		                <div class="content-tab info-content">
		                    <Paid ref="tab_1" :search="search" />
		                </div>
		            </b-tab>
		            <b-tab title="Complimentary" class="study-remain-tab table-custom">
		                <div class="content-tab info-content">
		                    <Complimentary ref="tab_2" :search="search" />
		                </div>
		            </b-tab>
		            <b-tab title="Terminated" class="study-remain-tab table-custom">
		                <div class="content-tab info-content">
		                    <Terminated ref="tab_3" :search="search" />
		                </div>
		            </b-tab>                        
		        </b-tabs>     
		    </b-col>
		</b-row>
	</section>
</template>
<script>
	import Breadcrumb from '@/views/itees/admin-dashboard/partial/Breadcrumb'

	import Overview from './tabs/Overview';
    import Paid from './tabs/Paid';
    import Complimentary from './tabs/Complimentary';
    import Terminated from './tabs/Terminated';
	import globalAdmin from '../../model/globalAdmin';
	export default {
		mixins: [globalAdmin],
	  	components: {
			Breadcrumb,
			Overview,
            Paid,
            Complimentary,
            Terminated
		},
	  	data() {
		    return {
		    	userData: JSON.parse(localStorage.getItem('userData')),
		    	permission: [],
				activity: {},
		    	labels: { "our-portfolio-study-visit": "" },
		    	tabIndex: 0,
		    	export_num: -1,
		    	search: null,
		    	timer: null,
		    }
		},
		watch: {
			tabIndex: function(val) {
                this.loadDataToTab(this.search)
                this.export_num = parseInt(val) - 1
            },
            "$route": function(val) {
				if(val){	
					if(this.$route && this.$route.query && this.$route.query.tab) this.tabIndex = Number(this.$route.query.tab)
				}
			},
			search(val) {
                this.loadDataToTab(val)                
            },  
		},	
		created(){
			this.permission = this.checkPermission(this.userData,"Study Visit") ?? []
			// console.log(this.permission,'888888888')
			if(this.$route && this.$route.query && this.$route.query.tab) this.tabIndex = Number(this.$route.query.tab)	
		},
		mounted() {
            this.$nextTick(() => {
                this.loadDataToTab(this.search)
            })
        },
		methods: {
			addItem(){
				this.$router.push(`/admin/our-portfolio/study-visit/add-visit`)
			},
			searchValue(val) {
				if (this.timer) {
			        clearTimeout(this.timer);
			        this.timer = null;			        
			    }
			    this.timer = setTimeout(() => {
			        this.search = val
			    }, 200);
			},
			loadDataToTab(val) {
                this.$refs[`tab_${this.tabIndex}`].currentPage = 1
                this.$refs[`tab_${this.tabIndex}`].fetchItem(val)
            },
			exportData(item) {
				if(item < 0){
					this.$store.dispatch('project/exportVisit', {}).then(response => {
						const url = window.URL.createObjectURL(new Blob([response.data]))
					    const link = document.createElement('a')
					    link.href = url
					    link.setAttribute('download', 'Study-visit-listing.csv') //or any other extension
					    document.body.appendChild(link)
					    link.click()
					})
					.catch((error) => {
						console.log(error)
					})
				}
				else{
					this.$store.dispatch('project/exportVisit', {
						type_engagement: item

					}).then(response => {
						const url = window.URL.createObjectURL(new Blob([response.data]))
					    const link = document.createElement('a')
					    link.href = url
					    link.setAttribute('download', 'Study-visit-listing.csv') //or any other extension
					    document.body.appendChild(link)
					    link.click()
					})
					.catch((error) => {
						console.log(error)
					})
				}
				
			},
		}
	};	

</script>

<style lang="scss">	
	@import "@/assets/scss/_ite_tab_table.scss";
</style>

