<template>
	<div>
	    <b-table small :fields="fields" :items="items" responsive="lg" tbody-tr-class="size-14 table-row" @row-clicked="detail" id="overview-table" class="mb-5 cursor-pointer" show-empty>
	    	<template #table-caption>Study Visit Listing</template>	
	    	<template #head(country_name)="data">
	    		<span>{{ data.label }}</span>
	    	</template>
			<template #head()="data">
	    		<span class="d-block text-center">{{ data.label }}</span>
	    	</template>    
			<template #cell(country_name)="data">
				<span>{{ data.item.country_name }}</span>
			</template>
			<template #cell()="data">
				<span class="d-block text-center">{{ data.value }}</span>
			</template>
			<template #empty="scope">
				<template v-if="search">
					<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data found</h4>
				</template>
				<template v-else>
					<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
				</template>
		    </template>	    
		</b-table>
		<div class="paging-cus relative">
			<b-pagination
		      	v-model="currentPage"
		      	:total-rows="total"
		      	:per-page="perPage"
		      	aria-controls="overview-table"
		      	first-number
	        	last-number
	        	class="just-center"
		    ></b-pagination>
		</div>
	</div>

</template>

<script>

	export default {
	  	props: {
            search: {
                type: String,
                default: null
            }
        },
	  	data() {
		    return {
                fields: [
			        { key: 'country_name', label: 'Country'},
			        { key: 'total_paid', label: 'Paid'},
			        { key: 'total_complimentary', label: 'Complimentary'},
			        { key: 'total_terminated', label: 'Terminated',},
			    ],
		    	items: [],
			    perPage: 10,
        		currentPage: 1, 
        		total: 0,
            }
		},
		watch: {
			currentPage(val) {
				this.fetchItem(this.search)
			}
		},
		created(){		
			this.fetchItem(this.search)
		},
		methods: {
		  	fetchItem(search){
				this.$store.dispatch('project/fetchStudyVisit', {
					limit: this.perPage,
					page: this.currentPage,
					search: search,
					filter: {						
					  	"list_search": [
						    {
						      "field": "status",
						      "keyword": "0"
						    }
					  ]
					},
					sort: {"field": "country_name","type": "asc"}
				})
				.then(response => {
					this.items = response.data.data.data
					this.total = response.data.data.total
					// console.log('item: ', response.data.data.data)
				})
				.catch((error) => {
					console.log(error)
				})
			},
			detail(item){
				this.$router.push(`/admin/our-portfolio/study-visit/${item.id}`)
			}
		},
	};	

</script>